import Toggle from '@atlaskit/toggle';
import React, { useEffect } from 'react';

const MfaToggle = ({ checked, isDisabled, onClickToggleMFARequire }) => {
  const [isChecked, setIsChecked] = React.useState(checked);
  const handleOnChangeMfaRequiredSite = async () => {
    if (!isChecked) {
      try {
        onClickToggleMFARequire(true);
        setIsChecked(true);
      } catch (error) {
        setIsChecked(false);
      }
    } else {
      // disable MFA and call API to turn off MFA
      onClickToggleMFARequire(false);
    }
  };

  useEffect(() => {
    // update isChecked when calling API to turn off MFA
    setIsChecked(checked);
  }, [checked]);

  return (
    <Toggle
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={handleOnChangeMfaRequiredSite}
    />
  );
};

export default MfaToggle;
