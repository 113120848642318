import { importAll } from '../utils';

const rawActions = importAll(require.context('./', true, /actions.js$/i), true);
const rawTypes = importAll(require.context('./', true, /types.js$/i));
const rawReducers = importAll(
  require.context('./', true, /reducer.js$/i),
  true,
);
const rawSagas = importAll(require.context('./', true, /saga.js$/i));

export const actions = rawActions.reduce((p, e) => ({ ...p, ...e.data }), {});
export const types = rawTypes.reduce((p, e) => ({ ...p, ...e.data }), {});
export const reducers = rawReducers.reduce(
  (p, e) => ({ ...p, [e.key]: e.data }),
  {},
);
export const sagas = rawSagas.reduce((p, e) => ({ ...p, ...e.data }), {});
