/* eslint-disable no-unused-vars, no-underscore-dangle */
import axios from 'axios';
import cognitoApis from '@root/services/cognitoApis';
import { store } from '../app/index';
// eslint-disable-next-line import/no-cycle
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '.';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) =>
    error ? prom.reject(error) : prom.resolve(token),
  );
  failedQueue = [];
};

export default (options = {}) => {
  const { headers = {} } = options;
  const createAxios = () =>
    axios.create({
      baseURL: '',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
        ...headers,
      },
    });
  const axiosInstance = createAxios();

  axiosInstance.interceptors.request.use(
    async (originConfig) => {
      const config = { ...originConfig };
      const state = store.getState();
      const { accessToken } = state.app;
      const token = accessToken || localStorage.getItem(ACCESS_TOKEN_KEY);
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const isCrossDomainError = !error.response;

      if (isCrossDomainError) return Promise.resolve(error);

      const isInternalServerError = error.response.status === 500;
      if (isInternalServerError) return Promise.resolve(error.response);

      const isBadRequest = error.response.status === 400;
      if (isBadRequest) return Promise.resolve(error.response);

      const isUnlicenseSite = error.response.status === 403;
      if (isUnlicenseSite) return Promise.resolve(error.response);

      const isNotFound = error.response.status === 404;
      if (isNotFound) {
        return Promise.resolve(error.response);
      }
      const isTokenExpired =
        error?.response?.status === 401 && !originalRequest._retry;

      if (isTokenExpired) {
        console.log('token expired');
        if (isRefreshing) {
          return new Promise((resolve, reject) =>
            failedQueue.push({ resolve, reject }),
          )
            .then((accessToken) => {
              originalRequest.headers.authorization = `Bearer ${accessToken}`;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }
        originalRequest._retry = true;
        isRefreshing = true;

        const _refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (_refreshToken) {
          return new Promise((resolve, reject) => {
            cognitoApis
              .refreshToken(_refreshToken)
              // eslint-disable-next-line consistent-return
              .then((response) => {
                if (response?.accessToken) {
                  const { idToken, refreshToken, accessToken } = response;
                  const newAccessToken = accessToken?.jwtToken;
                  const newRefreshToken = refreshToken?.token;
                  if (!newAccessToken || !newRefreshToken)
                    return Promise.reject(error);

                  localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
                  localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
                  axiosInstance.defaults.headers.common.authorization = `Bearer ${newAccessToken}`;
                  // replace the expired token and retry
                  originalRequest.headers.authorization = `Bearer ${newAccessToken}`;
                  processQueue(null, newAccessToken);
                  resolve(axios(originalRequest));
                  console.log('updated token');
                } else {
                  return Promise.reject(error);
                }
              })
              .catch((err) => {
                console.log('err', err);
                processQueue(err, null);
                // trường hợp api bị lỗi k lấy được token mới thì đẩy ra trang login
                localStorage.clear();
                window.location.href = `${window.location.origin}/login?state=${window.location.href}`;
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        localStorage.clear();
        // undefined refreshToken
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};
