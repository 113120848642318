import { backEndApi } from '../utils';

const api = '/api/v1';
const auditLogPath = '/audit-log';

const S3APIs = {
  getAuditLog: (data) =>
    backEndApi.post(`${api}${auditLogPath}/${data.id}`, data.body),

  checkAuditLog: (data) =>
    backEndApi.post(
      `${api}${auditLogPath}/${data.id}/${data.logType}/checkAccessKey`,
    ),

  getLastUpdateDateProducts: (payload) =>
    backEndApi.post(`${api}${auditLogPath}/product-log/updateDate`, payload),

  getLastUpdateDateOrganizations: (payload) =>
    backEndApi.post(
      `${api}${auditLogPath}/organization-log/updateDate`,
      payload,
    ),

  testMultipleS3Credentials: (payload) =>
    backEndApi.post(`${api}${auditLogPath}/settings/storages/test`, payload),

  testAccessDataTransfer: (payload) =>
    backEndApi.post(
      `${api}${auditLogPath}/settings/storage/test-transfer-access`,
      payload,
    ),

  getStorageSettings: (payload) =>
    backEndApi.post(`${api}${auditLogPath}/settings/storage`, payload),

  getRecordstorageSettings: (payload) =>
    backEndApi.post(`${api}${auditLogPath}/settings/storage/records`, payload),

  removeStorageSettings: (id) =>
    backEndApi.delete(`${api}${auditLogPath}/settings/storage/${id}`),

  saveStorageSettings: (payload) =>
    backEndApi.put(`${api}${auditLogPath}/settings/storage/save`, payload),

  retryCopyData: (payload) =>
    backEndApi.post(
      `${api}${auditLogPath}/settings/storage/retryCopy`,
      payload,
    ),
};

export default S3APIs;
