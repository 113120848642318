import React from 'react';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';

const SORT_KEY = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: '',
};

const renderIconSort = (key) => {
  if (key === SORT_KEY.ASC) {
    return <ArrowUpIcon size='small' />;
  }
  if (key === SORT_KEY.DESC) {
    return <ArrowDownIcon size='small' />;
  }
  return '';
};

export { renderIconSort, SORT_KEY };
