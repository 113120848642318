/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import InviteIcon from '@atlaskit/icon/glyph/email';
import SearchIcon from '@atlaskit/icon/glyph/search';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Pagination from '@atlaskit/pagination';
import Popup from '@atlaskit/popup';
import Select from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { Status } from '@atlaskit/status/element';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import SiteListSelectField from '@root/components/SiteListSelectField';
import PaginationTotalNumber from '@root/components/common/PaginationTotalNumber';
import { siteAPIs } from '@root/services';
import mfaAPIs from '@root/services/mfaApis';
import {
  ACTION_USER_TYPE_CONFIRM,
  emptyTable,
  errorMessageLicense,
  isSiteDisable,
} from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux';
import { ALERT_TYPE } from '../common/MessageSnackbar';
import useSiteList from '../security/useSiteList';
import ActionUserConfirmModal from './ActionUserConfirmModal';
import InviteUserDialog from './InviteUserModal';
import MfaToggle from './MfaToggle';
import UserManagerContext, {
  UserManagerDefault,
  fetchCurrentUsers,
  getProductNames,
} from './UserManagerContext';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 62px)',
    padding: '0px 5px 20px 5px',
    paddingBottom: '1rem',
    '& table': {
      fontSize: 14,
    },
    '& th': {
      background: '#F2F2F2',
      color: '#000',
      '&:focus': {
        outline: 'solid 2px transparent',
      },
    },
    '& th:first-child, & td:first-child': {
      paddingLeft: 8,
    },
    '& th >span::before, & th >span::after': {
      display: 'none',
    },
    '& .MuiIconButton-sizeSmall': {
      padding: '2px',
    },
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    lineHeight: '26px',
  },
  optionDisable: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '45%',
    top: '86%',
  },
  tableResponsive: {
    '& > table': {
      minWidth: 700,
    },
    '& nav': {
      display: 'none',
    },
  },
  spinner: {
    height: '50vh',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navbar: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputForm: {
    width: '300px',
  },
  warning: {
    display: 'flex',
    fontSize: '15px',
    color: '#DE350B',
    paddingTop: '5px',
    paddingBottom: '15px',
    width: '100%',
  },
  title: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '20px',
    color: '#091E42',
    marginBottom: '15px',
  },
  searchContainer: {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  inviteIcon: {
    marginRight: '0',
    width: '60%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterName: {
    marginLeft: '50px',
  },
  main: {
    height: '415px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& th': {
      fontSize: '14px',
    },
  },
  displayName: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& span:nth-child(2)': {
      marginLeft: '3px',
    },
  },
  email: {
    display: 'flex',
  },
  avatar: {
    marginRight: '0.5rem',
  },
  wrapSpinner: {
    display: 'inline-block',
    marginLeft: 16,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  header: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
  },

  tableHeader: {
    display: 'flex',
    width: '10px',
    backgroundColor: 'none',
  },
  sortableIcon: {
    marginTop: 4,
    '&:before': {
      border: '3px solid transparent',
      display: 'block',
      height: 0,
      width: 0,
      borderBottom:
        '3px solid var(--ds-background-subtleNeutral-resting,#DFE1E6)',
      content: '""',
    },
    '&:after': {
      border: '3px solid transparent',
      display: 'block',
      height: 0,
      right: -8,
      width: 0,
      marginTop: 2,
      borderTop: '3px solid var(--ds-background-subtleNeutral-resting,#DFE1E6)',
      bottom: 0,
      content: '""',
    },
    '&.sort-ASC:before': {
      borderBottom: '3px solid var(--ds-text-lowEmphasis,#5E6C84)',
    },
    '&.sort-DESC:after': {
      borderTop: '3px solid var(--ds-text-lowEmphasis,#5E6C84)',
    },
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  titleWrapper: {
    fontSize: '20px',
    fontWeight: 500,
  },
  searchItem: {
    minWidth: 230,
    fontSize: '14px',
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
    '& input[name="keyword"]': {
      height: 36,
    },
  },
  search: {
    width: '75%',
    display: 'flex',
    justifyContent: 'end',
    '& > div:nth-child(2)': {
      marginLeft: '10px',
    },
    '& > div:nth-child(3)': {
      marginLeft: '10px',
    },
  },
  footerStyles: {
    display: 'flex',
    padding: '45px 10px 14px 10px',
    alignItems: 'center',
    justifyContent: 'end',
  },
  activeFilter: {
    '&:not([aria-expanded="true"])': {
      color: '#0052CC !important',
      '& span svg': {
        color: '#0052CC !important',
      },
    },
  },
  jiraHeaderCenter: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minHeight: 40,
    padding: 4,
    borderRight: '2px #e2e2e2 solid',
    cursor: 'pointer',
    gap: '0 5px',
    '&:hover::before': {
      content: '" "',
      width: '100%',
      position: 'absolute',
      background: '#fefefe',
      bottom: '-20px',
      top: '-5px',
      zIndex: -1,
    },
  },
  topActionWrapper: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '0 8px',
    marginBottom: '-15px',
    fontSize: 14,
    color: '#45526C',
    minHeight: 40,
    flexWrap: 'wrap',
    '& p, & button': {
      fontSize: 14,
    },
    '& > .left-actions': {
      display: 'flex',
      alignItems: 'center',
      gap: '0 8px',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#45526C',
    },
  },

  rowsPerPageOpt: {
    minWidth: 130,
    fontSize: '14px',
    '&:before': {
      display: 'none !important',
    },
  },

  searchPopupButton: {
    marginTop: 21,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0 5px',
  },

  searchButtonContainer: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DFE1E6',
    },
  },
  userAtlassianNotActive: {
    opacity: 0.3,
  },
}));

const UsersBoard = (props) => {
  const { actions: action } = useSiteList();
  const siteListOption = action.getSiteOptions();
  const { setDefaultSite, setIsOpen, defaultRowsPerPage = 10 } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [userManagemerState, setUserManagerState] =
    useState(UserManagerDefault);
  const { atlassianUsers, siteUsers, isInvitatorEnable } = userManagemerState;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.app?.currentUser);
  const [reinvitingAccountId, setReinvitingAccountId] = useState(null);
  // action off MFA, remove user,  reset device MFA
  const [actionConfirmAccountId, setActionConfirmAccountId] = useState(null);

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [exceedUserProduct, setExceedUserProduct] = useState([]);
  const [selectedSort, setSelectedSort] = useState({ key: '', sortOrder: '' });

  const [userData, setUserData] = useState([]);

  // table pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState({
    label: '',
    value: '',
  });

  // search
  const [searchKeyName, setSearchKeyName] = useState('');
  const [searchKeyEmail, setSearchKeyEmail] = useState('');

  // search Popup
  const [isOpenSearchName, setisOpenSearchName] = React.useState(false);
  const [isOpenSearchEmail, setisOpenSearchEmail] = React.useState(false);
  const [searchBy, setSearchBy] = React.useState('');
  const [site, setSite] = React.useState(null);
  const [searchTempValue, setSearchTempValue] = React.useState({
    name: '',
    email: '',
  });

  // notification confirm
  const [notificationConfirm, setNotificationConfirm] = React.useState({
    type: '',
    title: '',
    message: '',
    name: '',
    submitText: '',
  });

  const handleSearchFilter = (siteData) => {
    setSite(siteData);
    setDefaultSite(siteData?.siteId);
    setCurrentPage(1);
    if (!site) {
      newRelicUtils.addPageAction(PAGE_ACTION.userManagement.open, {
        siteIds: siteData?.siteId,
      });
    } else {
      newRelicUtils.addPageAction(PAGE_ACTION.userManagement.changeSite, {
        siteIds: siteData?.siteId,
      });
    }
  };

  const renderEmailSection = (email) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {email || t('activity.emailOutOfOrg')}
    </div>
  );

  const CustomFooter = () => (
    <div className={classes.footerStyles}>
      <Button
        appearance='subtle'
        onClick={() => {
          setIsOpen('');
          newRelicUtils.addPageAction(PAGE_ACTION.userManagement.cannel, {
            siteIds: site?.siteId,
          });
        }}
      >
        {t('common.cancel')}
      </Button>
    </div>
  );

  const loadUsers = async () => {
    setLoadingUsers(true);
    const {
      siteUsers: fetchSiteUsers,
      isInvitatorEnable: fetchIsInvitatorEnable,
    } = await fetchCurrentUsers(site?.siteId);
    const siteUsersIds = fetchSiteUsers.map((u) => u.atlassianAccountId);

    let atlasUsers = [];
    try {
      const { data } = await siteAPIs.getUsersFromAtlassianByIds(
        site?.siteId,
        siteUsersIds,
      );
      atlasUsers = data.users;
    } catch (err) {
      // Do nothing
    }
    setUserManagerState({
      atlassianUsers: atlasUsers,
      siteUsers: fetchSiteUsers,
      isInvitatorEnable: fetchIsInvitatorEnable,
    });
    setLoadingUsers(false);
  };

  const handleSearch = () => {
    let tempData = cloneDeep(siteUsers);
    if (searchKeyName.trim().length) {
      tempData = tempData?.filter((item) =>
        item?.name?.toLowerCase().includes(searchKeyName.toLowerCase()),
      );
    }
    if (searchKeyEmail.trim().length) {
      tempData = tempData?.filter((item) =>
        item?.email?.toLowerCase().includes(searchKeyEmail.toLowerCase()),
      );
    }
    setUserData(tempData);
  };

  // add displayName property to siteUser
  // eslint-disable-next-line array-callback-return
  siteUsers?.map((user) => {
    const atlasUser = atlassianUsers?.find(
      (u) => u?.accountId === user.atlassianAccountId,
    );
    // eslint-disable-next-line no-param-reassign
    user.name = atlasUser?.displayName || '';
  });
  const loadUsersCallback = useCallback(loadUsers, [site?.siteId]);

  const checkExceedUserProducts = async () => {
    if (site?.siteId) {
      setExceedUserProduct(await getProductNames(site?.siteId));
    }
  };

  const onClickInviteBtn = async () => {
    // enable invite in confluence too
    setIsInviteDialogOpen(true);
  };

  const reInviteUser = async (accountId) => {
    newRelicUtils.addPageAction(PAGE_ACTION.userManagement.reinvite, {
      siteIds: site?.siteId,
      targetIds: accountId,
    });
    setReinvitingAccountId(accountId);
    try {
      await siteAPIs.resetUserPasswordFromSite(site?.siteId, accountId);
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.success,
          messageKey: 'snackbar.reinviteSiteUserOk',
        }),
      );
    } catch (reinviteUserError) {
      // console.log(reinviteUserError)
      dispatch(
        actions.openSnackbar({
          open: true,
          type: ALERT_TYPE.error,
          messageKey: 'snackbar.failedToReInviteUser',
        }),
      );
    }
    setReinvitingAccountId(null);
  };

  const renderIconSort = () => {
    if (selectedSort.key === 'email') {
      if (selectedSort.sortOrder === 'ASC') {
        return <ArrowDownIcon size='small' />;
      }
      if (selectedSort.sortOrder === 'DESC') {
        return <ArrowUpIcon size='small' />;
      }
    }
    if (selectedSort.key === 'name') {
      if (selectedSort.sortOrder === 'ASC') {
        return <ArrowDownIcon size='small' />;
      }
      if (selectedSort.sortOrder === 'DESC') {
        return <ArrowUpIcon size='small' />;
      }
    }
    return '';
  };

  const handleReset = (sBy) => {
    switch (sBy) {
      case 'name':
        setSearchKeyName('');
        setSearchTempValue({
          ...searchTempValue,
          [sBy]: '',
        });
        break;
      case 'email':
        setSearchKeyEmail('');
        setSearchTempValue({
          ...searchTempValue,
          [sBy]: '',
        });
        break;
      default:
        setSearchKeyEmail('');
        setSearchKeyName('');
        break;
    }
  };

  const handleSearchUser = (e) => {
    const value = e?.currentTarget?.value || searchTempValue[searchBy];
    // if (e?.currentTarget?.value) setSearchTempValue(...);
    if (searchBy === 'name') setSearchKeyName(value);
    if (searchBy === 'email') setSearchKeyEmail(value);
  };

  const renderSearchPopup = ({ setInitialFocusRef }) => (
    <Paper
      elevation={1}
      style={{
        border: 1,
        padding: '15px 15px 25px',
        marginTop: 20,
        minWidth: 250,
      }}
    >
      <Textfield
        ref={setInitialFocusRef}
        defaultValue={searchTempValue[searchBy]}
        onChange={(event) => {
          setSearchTempValue({
            ...searchTempValue,
            [searchBy]: event.target.value,
          });
        }}
        value={searchTempValue[searchBy]}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearchUser(e);
            setisOpenSearchName(false);
            setisOpenSearchEmail(false);
          }
        }}
        placeholder={
          searchBy === 'name'
            ? t('userManagement.searchPlaceHolderByName')
            : t('userManagement.searchPlaceHolderByEmail')
        }
        autoFocus
      />
      <div className={classes.searchPopupButton}>
        <Button
          appearance='subtle'
          size={14}
          onClick={() => {
            setSearchTempValue({
              ...searchTempValue,
              [searchBy]: '',
            });
            setisOpenSearchName(false);
            setisOpenSearchEmail(false);
            handleReset(searchBy);
          }}
        >
          {t('userManagement.reset')}
        </Button>
        <Button
          size='small'
          iconBefore={<SearchIcon size='small' />}
          appearance='primary'
          onClick={() => {
            handleSearchUser();
            setisOpenSearchName(false);
            setisOpenSearchEmail(false);
          }}
        >
          {t('userManagement.searchBtn')}
        </Button>
      </div>
    </Paper>
  );

  const head = {
    cells: [
      {
        key: 'name',
        content: (
          <div className={classes.jiraHeaderCenter}>
            <Tooltip content={t('common.sort')}>
              {(tooltipProps) => (
                <span
                  {...tooltipProps}
                  style={{ paddingRight: '5px', cursor: 'pointer', flex: 1 }}
                  onClick={() => {
                    if (selectedSort.key === 'name') {
                      setSelectedSort({
                        key: 'name',
                        sortOrder:
                          selectedSort.sortOrder === 'ASC' ? 'DESC' : 'ASC',
                      });
                    } else {
                      setSelectedSort({
                        key: 'name',
                        sortOrder: 'ASC',
                      });
                    }
                  }}
                >
                  {selectedSort.key === 'name' && renderIconSort()}
                  {t('userManagement.userList.nameHeading')}
                </span>
              )}
            </Tooltip>

            <Popup
              zIndex={999}
              isOpen={isOpenSearchName}
              onClose={() => setisOpenSearchName(false)}
              placement='bottom-start'
              content={({ setInitialFocusRef }) =>
                renderSearchPopup({ setInitialFocusRef })
              }
              trigger={(triggerProps) => (
                <div
                  {...triggerProps}
                  className={`${classes.searchButtonContainer} ${
                    searchTempValue.name && classes.activeFilter
                  }`}
                  disabled={!!(isOpenSearchName && searchBy === 'name')}
                  onClick={() => {
                    setisOpenSearchName(!isOpenSearchName);
                    setSearchBy('name');
                  }}
                >
                  <Tooltip content={t('activity.searchBtn')}>
                    {(tooltipProps) => (
                      <SearchIcon
                        {...tooltipProps}
                        size='small'
                        primaryColor='#6D6E6F'
                      />
                    )}
                  </Tooltip>
                </div>
              )}
            />
          </div>
        ),
        width: 30,
      },
      {
        key: 'email',
        content: (
          <div className={classes.jiraHeaderCenter}>
            <Tooltip content={t('common.sort')}>
              {(tooltipProps) => (
                <span
                  {...tooltipProps}
                  style={{ paddingRight: '5px', cursor: 'pointer', flex: 1 }}
                  onClick={() => {
                    if (selectedSort.key === 'email') {
                      setSelectedSort({
                        key: 'email',
                        sortOrder:
                          selectedSort.sortOrder === 'ASC' ? 'DESC' : 'ASC',
                      });
                    } else {
                      setSelectedSort({
                        key: 'email',
                        sortOrder: 'ASC',
                      });
                    }
                  }}
                >
                  {selectedSort.key === 'email' && renderIconSort()}
                  {t('userManagement.userList.emailHeading')}
                </span>
              )}
            </Tooltip>

            <Popup
              zIndex={999}
              isOpen={isOpenSearchEmail}
              onClose={() => setisOpenSearchEmail(false)}
              placement='bottom-start'
              content={({ setInitialFocusRef }) =>
                renderSearchPopup({ setInitialFocusRef })
              }
              trigger={(triggerProps) => (
                <div
                  {...triggerProps}
                  className={`${classes.searchButtonContainer} ${
                    searchTempValue.email && classes.activeFilter
                  }`}
                  disabled={!!(isOpenSearchEmail && searchBy === 'email')}
                  onClick={() => {
                    setisOpenSearchEmail(!isOpenSearchEmail);
                    setSearchBy('email');
                  }}
                >
                  <Tooltip content={t('activity.searchBtn')}>
                    {(tooltipProps) => (
                      <SearchIcon
                        {...tooltipProps}
                        size='small'
                        primaryColor='#6D6E6F'
                      />
                    )}
                  </Tooltip>
                </div>
              )}
            />
          </div>
        ),
        width: 30,
      },
      {
        key: 'status',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.statusHeading')}
          </div>
        ),
        isSortable: false,
        width: 20,
      },
      {
        key: 'mfa_required',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.mfaRequired')}
          </div>
        ),
        isSortable: false,
        width: 20,
      },
      {
        key: 'mfa_status',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.mfaStatus')}
          </div>
        ),
        isSortable: false,
        width: 20,
      },
      {
        key: 'action',
        content: (
          <div className={classes.jiraHeaderCenter}>
            {t('userManagement.userList.actionHeading')}
          </div>
        ),
        isSortable: false,
        width: 20,
      },
    ],
  };

  const handleFinalData = (data) => {
    if (!data || data.length === 0) {
      return [];
    }
    const checkDataType = (val) => val || '';
    return [...data].sort((a, b) => {
      if (selectedSort.key) {
        return selectedSort.sortOrder === 'ASC'
          ? checkDataType(a?.[selectedSort.key]).localeCompare(
              checkDataType(b?.[selectedSort.key]),
            )
          : checkDataType(b?.[selectedSort.key]).localeCompare(
              checkDataType(a?.[selectedSort.key]),
            );
      }
      return data;
    });
  };

  const rows = handleFinalData(userData)
    .sort((a, b) => {
      const user1Index = atlassianUsers.findIndex(
        (u) => u?.accountId === a.atlassianAccountId,
      );
      const user2Index = atlassianUsers.findIndex(
        (u) => u?.accountId === b.atlassianAccountId,
      );
      if (
        atlassianUsers[user1Index]?.active &&
        !atlassianUsers[user2Index]?.active
      ) {
        return -1;
      }
      if (
        !atlassianUsers[user1Index]?.active &&
        atlassianUsers[user2Index]?.active
      ) {
        return 1;
      }
      return 0;
    })
    .map((user) => {
      const atlasUser = atlassianUsers.find(
        (u) => u?.accountId === user.atlassianAccountId,
      );
      return {
        key: user.accountId,
        cells: [
          {
            key: user.name,
            content: (
              <div
                className={
                  atlasUser?.active
                    ? classes.displayName
                    : `${classes.displayName} ${classes.userAtlassianNotActive}`
                }
              >
                <div className={classes.avatar}>
                  {atlasUser?.avatarUrls ? (
                    <Avatar
                      appearance='circle'
                      src={
                        atlasUser.avatarUrls['32x32'] ||
                        atlasUser.avatarUrls['48x48']
                      }
                      size='medium'
                      name={atlasUser?.displayName}
                    />
                  ) : (
                    <Avatar
                      appearance='circle'
                      src=''
                      size='medium'
                      name={atlasUser?.displayName}
                    />
                  )}
                </div>
                <span>{atlasUser?.displayName}</span>
              </div>
            ),
          },
          {
            key: user.email,
            content: (
              <div
                className={
                  atlasUser?.active ? '' : classes.userAtlassianNotActive
                }
              >
                {renderEmailSection(user?.email)}
              </div>
            ),
          },
          {
            key: 'status',
            content: (
              <>
                {atlasUser?.active ? (
                  <Status
                    text={
                      user.status === 'CONFIRMED'
                        ? t('userManagement.status.active')
                        : t('userManagement.status.notLoginYet')
                    }
                    color={user.status === 'CONFIRMED' ? 'green' : 'yellow'}
                  />
                ) : (
                  <Status
                    text={t('userManagement.status.inactive')}
                    color='neutral'
                  />
                )}
              </>
            ),
          },
          {
            key: 'mfa_required',
            content: (
              <MfaToggle
                checked={user?.mfaRequired}
                isDisabled={atlasUser?.active && user.status !== 'CONFIRMED'}
                onClickToggleMFARequire={async (newValue) => {
                  if (newValue) {
                    // enable MFA required
                    newRelicUtils.addPageAction(
                      PAGE_ACTION.userManagement.turnOnMFARequire,
                      {
                        siteIds: site?.siteId,
                        targetIds: user?.userId,
                      },
                    );
                    const body = {
                      accountId: user?.userId,
                      siteId: site?.siteId,
                    };
                    await mfaAPIs.changeMfaRequiredSite(body);
                  } else {
                    // disable MFA required
                    setActionConfirmAccountId(user.userId);
                    setNotificationConfirm({
                      type: ACTION_USER_TYPE_CONFIRM.TURN_OFF_MFA,
                      name: user.name,
                      title: t('userManagement.dialog.turnOffMFA.title'),
                      message: t('userManagement.dialog.turnOffMFA.message'),
                      submitText: t('common.save'),
                    });
                  }
                }}
              />
            ),
          },
          {
            key: 'mfa_status',
            content: (
              <>
                {user?.mfaStatus ? (
                  <Status
                    text={t('userManagement.status.registered')}
                    color='green'
                  />
                ) : (
                  <Status
                    text={t('userManagement.status.unregistered')}
                    color='neutral'
                  />
                )}
              </>
            ),
          },
          {
            key: 'action',
            content: (
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <Button
                  style={{ paddingRight: '10px', paddingLeft: '0px' }}
                  isDisabled={user.userId === currentUser.id}
                  appearance='link'
                  onClick={() => {
                    setActionConfirmAccountId(user.atlassianAccountId);
                    setNotificationConfirm({
                      type: ACTION_USER_TYPE_CONFIRM.REMOVE_USER,
                      name: user.name,
                      title: t('userManagement.dialog.removeUser.title'),
                      message: t('userManagement.dialog.removeUser.message'),
                      submitText: t('common.remove'),
                    });
                  }}
                >
                  {t('common.remove')}
                </Button>
                {user.status !== 'CONFIRMED' &&
                  (reinvitingAccountId === atlasUser?.accountId ? (
                    <div className={classes.wrapSpinner}>
                      <Spinner size='medium' />
                    </div>
                  ) : (
                    <Button
                      style={{ paddingRight: '10px', paddingLeft: '0px' }}
                      isDisabled={user.userId === currentUser.id}
                      appearance='link'
                      onClick={() => {
                        reInviteUser(user.atlassianAccountId);
                      }}
                    >
                      {t('common.reinvite')}
                    </Button>
                  ))}
                {user?.mfaStatus && (
                  <Button
                    style={{ paddingRight: '10px', paddingLeft: '0px' }}
                    appearance='link'
                    onClick={() => {
                      setActionConfirmAccountId(user.userId);
                      setNotificationConfirm({
                        type: ACTION_USER_TYPE_CONFIRM.RESET_DEVICE_MFA,
                        name: user.name,
                        title: t('userManagement.dialog.resetMFA.title'),
                        message: t('userManagement.dialog.resetMFA.message'),
                        submitText: t('userManagement.resetMfa'),
                      });
                    }}
                  >
                    {t('userManagement.resetMfa')}
                  </Button>
                )}
              </div>
            ),
          },
        ],
      };
    });

  const totalPages = useMemo(
    () =>
      Math.ceil(
        userData?.length / (rowsPerPage?.value || defaultRowsPerPage),
      ) || 1,
    [JSON.stringify(siteUsers), JSON.stringify(userData), rowsPerPage],
  );

  useEffect(() => {
    checkExceedUserProducts();
  }, [site?.siteId]);

  useEffect(() => {
    if (site?.siteId) {
      if (!isSiteDisable(site)) loadUsersCallback(site?.siteId);
      else setLoadingUsers(false);
    }
  }, [site?.siteId]);

  useEffect(() => {
    setUserData(siteUsers);
    setCurrentPage(1);
    handleReset();
    setSearchTempValue('');
    setSearchBy('');
  }, [JSON.stringify(siteUsers)]);

  useEffect(() => {
    setRowsPerPage({
      label: `${defaultRowsPerPage} / ${t('userManagement.page')}`,
      value: defaultRowsPerPage,
    });
  }, [defaultRowsPerPage]);

  useEffect(() => {
    handleSearch();
  }, [searchKeyEmail, searchKeyName, JSON.stringify(siteUsers)]);

  const indexExceedUserProduct = exceedUserProduct.indexOf(
    'Jira Product Disovery',
  );
  if (indexExceedUserProduct >= 0) {
    exceedUserProduct.splice(indexExceedUserProduct, 1);
  }

  return (
    <UserManagerContext.Provider
      value={{
        atlassianUsers,
        siteUsers,
        siteId: site?.siteId,
        isInvitatorEnable,
        loadUsers: loadUsersCallback,
      }}
    >
      <ModalTransition>
        <Modal width='100vw' autoFocus={false} height='100%'>
          <ModalHeader>
            <div className={classes.header}>
              <div className={classes.top}>
                <span className={classes.titleWrapper}>
                  {t('navBar.userManagePage')}
                </span>
                <span className={classes.search}>
                  <div className={classes.searchItem}>
                    <SiteListSelectField
                      inputId='single-select-example'
                      className='single-select'
                      classNamePrefix='react-select'
                      onChange={(siteData) => {
                        if (siteData) {
                          handleSearchFilter(siteData);
                        }
                      }}
                      isSearchable={false}
                      options={siteListOption}
                      placeholder={t('userManagement.selectSitePlaceholder')}
                    />
                  </div>
                  <Button
                    iconBefore={<InviteIcon />}
                    appearance='primary'
                    onClick={onClickInviteBtn}
                    style={{ marginLeft: '10px' }}
                    isDisabled={isSiteDisable(site)}
                  >
                    {t('userManagement.inviteButtonLabel')}
                  </Button>
                </span>
              </div>
              {!isSiteDisable(site) && (
                <>
                  {' '}
                  {exceedUserProduct.length && site?.siteId ? (
                    <div className={classes.warning}>
                      <WarningIcon size='small' primaryColor='#E5686F' />
                      <span>{`${t(
                        'userManagement.warning1',
                      )} ${exceedUserProduct.toString()}. ${t(
                        'userManagement.warning2',
                      )}`}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={classes.topActionWrapper}>
                    {site?.siteId && (
                      <div className='left-actions'>
                        <Select
                          blurInputOnSelect
                          className={classes.rowsPerPageOpt}
                          value={rowsPerPage}
                          inputValue=''
                          isSearchable={false}
                          options={[
                            {
                              label: `${defaultRowsPerPage} / ${t(
                                'userManagement.page',
                              )}`,
                              value: defaultRowsPerPage,
                            },
                            {
                              label: `20 / ${t('userManagement.page')}`,
                              value: 20,
                            },
                            {
                              label: `50 / ${t('userManagement.page')}`,
                              value: 50,
                            },
                            {
                              label: `100 / ${t('userManagement.page')}`,
                              value: 100,
                            },
                          ]}
                          onChange={(newValue) => {
                            setRowsPerPage(newValue);
                            setCurrentPage(1);
                          }}
                        />
                        {userData?.length > 0 ? (
                          <PaginationTotalNumber
                            currentPage={currentPage}
                            actualItem={userData?.length || 0}
                            rowsPerPage={rowsPerPage.value}
                            totalPage={userData?.length || 0}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </ModalHeader>
          <ModalBody>
            {isSiteDisable(site) ? (
              <>{errorMessageLicense()}</>
            ) : (
              <>
                {site?.siteId && (
                  <>
                    <div className={classes.main}>
                      {loadingUsers ? (
                        <div className={classes.loading}>
                          <Spinner size='large' />
                        </div>
                      ) : (
                        <div className={classes.container}>
                          <div className={classes.tableResponsive}>
                            <DynamicTable
                              isLoading={loadingUsers}
                              head={head}
                              emptyView={emptyTable()}
                              rows={rows}
                              rowsPerPage={rowsPerPage?.value}
                              defaultPage={1}
                              page={currentPage}
                              isFixedSize='true'
                            />
                          </div>
                          {userData?.length !== 0 && (
                            <div className={classes.pagination}>
                              <Pagination
                                defaultSelectedIndex={currentPage - 1}
                                selectedIndex={currentPage - 1}
                                pages={Array.from(
                                  {
                                    length: totalPages,
                                  },
                                  (_v, i) => i + 1,
                                )}
                                onChange={(data, page) => setCurrentPage(page)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {isInviteDialogOpen && (
                      <InviteUserDialog
                        onClose={() => setIsInviteDialogOpen(false)}
                      />
                    )}
                    {actionConfirmAccountId && (
                      <ActionUserConfirmModal
                        accountId={actionConfirmAccountId}
                        onClose={() => setActionConfirmAccountId(null)}
                        notification={notificationConfirm}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </ModalBody>
          <CustomFooter />
        </Modal>
      </ModalTransition>
    </UserManagerContext.Provider>
  );
};

export default UsersBoard;
