import { backendProxyApi } from '../utils';

const api = '/api/v1';
const sitePath = '/proxy';
const atlasAPIs = {
  proxyApi: (options, body) =>
    backendProxyApi(options).post(`${api}${sitePath}/atlassian`, body),
  proxyApiByApiToken: (options, body) =>
    backendProxyApi(options).post(
      `${api}${sitePath}/atlassianByApiToken`,
      body,
    ),
};
export default atlasAPIs;
