import React from 'react';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import appActions from '@root/redux/app/actions';
import { makeStyles } from '@material-ui/styles';
import Form from '@atlaskit/form';

const useStyles = makeStyles(() => ({
  footerGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

const MODAL_KEY = {
  FIRST_INSTALL: 'install',
  INSTALL_EDIT_SETTING: 'edit_settings',
  APPLY_PROMOTION_CODE: 'apply_promotion_code',
  EDIT_ORG_API_KEY: 'EDIT_ORG_API_KEY',
  DELETE_ORG_API_KEY: 'DELETE_ORG_API_KEY',
  ADD_ORG_API_KEY: 'ADD_ORG_API_KEY',
};

const CustomModal = ({
  title,
  isOpen,
  onClose,
  children,
  modalKey,
  onSubmit,
  renderTitleIcon = () => {},
  renderFooterButtons = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOnClose = () => {
    onClose();
    dispatch(
      appActions.toggleModal({
        key: modalKey,
      }),
    );
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal>
          <Form onSubmit={onSubmit}>
            {({ formProps, submitting }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>
                    <div className={classes.title}>
                      {title}
                      {renderTitleIcon()}
                    </div>
                  </ModalTitle>
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
                <ModalFooter>
                  <div className={classes.footerGroup}>
                    <Button appearance='subtle' onClick={handleOnClose}>
                      {t('common.cancel')}
                    </Button>
                    {renderFooterButtons(submitting)}
                  </div>
                </ModalFooter>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  );
};
export { MODAL_KEY, CustomModal };
