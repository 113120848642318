import { backEndApi } from '@root/utils';

const api = '/api/v1';
const mfaPath = '/mfa';

const mfaAPIs = {
  getMfaStatus: (userID) => backEndApi.get(`${api}${mfaPath}?id=${userID}`),
  changeMfaRequiredSite: (body) => backEndApi.post(`${api}${mfaPath}`, body),
  resetDeviceMFA: (body) =>
    backEndApi.post(`${api}${mfaPath}/reset-device`, body),
  setupDeviceMFA: (body) =>
    backEndApi.post(`${api}${mfaPath}/setup-device`, body),
  verifyDeviceMFA: (body) =>
    backEndApi.post(`${api}${mfaPath}/verify-device`, body),
};

export default mfaAPIs;
