import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

export default {
  fetchProjectTasks: (payload) => ({
    type: types.FETCH_PROJECT_TASK_SAGA,
    payload,
  }),
  addIssueDescriptionSaga: (payload) => ({
    type: types.ADD_ISSUE_DESCRIPTION_SAGA,
    payload,
  }),
  addIssueCommentSaga: (payload) => ({
    type: types.ADD_ISSUE_COMMENT_SAGA,
    payload,
  }),
  updateIssueSaga: (payload) => ({
    type: types.UPDATE_ISSUE_SAGA,
    payload,
  }),
  deleteIssueCommentSaga: (payload) => ({
    type: types.DELETE_ISSUE_COMMENT_SAGA,
    payload,
  }),
  editIssueCommentSaga: (payload) => ({
    type: types.EDIT_ISSUE_COMMENT_SAGA,
    payload,
  }),
  fetchIssueTypeSaga: (payload) => ({
    type: types.FETCH_ISSUE_TYPE_SAGA,
    payload,
  }),
  fetchIssuePrioritiesSaga: (payload) => ({
    type: types.FETCH_ISSUE_PRIORITIES,
    payload,
  }),
  fetchIssueCreatemetaSaga: (payload) => ({
    type: types.FETCH_ISSUE_CREATEMETA,
    payload,
  }),
  editIssueSaga: (payload) => ({ type: types.EDIT_ISSUE_FIELDS_SAGA, payload }),
  editIssueStatusSaga: (payload) => ({
    type: types.EDIT_ISSUE_STATUS_SAGA,
    payload,
  }),
  fetchFieldsSaga: (payload) => ({ type: types.FETCH_FIELD_SAGA, payload }),

  setLoading: createAction(types.PROJECT_PROCESSING),
  setTaskList: createAction(types.SET_TASK_LIST),
  setProjectList: createAction(types.SET_PROJECT_LIST),
  setUserAssignable: createAction(types.SET_USER_ASSIGNABLE),
  deleteTask: createAction(types.DELETE_TASK),
  setSelectedSite: createAction(types.SET_SELECTED_SITE),
  setSelectedItem: createAction(types.SET_SELECTED_ITEM),
  updateTaskExpand: createAction(types.UPDATE_TASK_EXPAND_STATUS),
  updateTask: createAction(types.UPDATE_TASK),
  setStartDateField: createAction(types.SET_START_DATE_FIELD),
  setTreeData: createAction(types.SET_TREE_DATA),
  addTaskComment: createAction(types.ADD_TASK_COMMENT),
  deleteTaskComment: createAction(types.DELETE_TASK_COMMENT),
  editTaskComment: createAction(types.EDIT_TASK_COMMENT),
  editTaskDescription: createAction(types.EDIT_TASK_DESCRIPTION),
  showUploadAttachmentDialog: createAction(types.SHOW_UPLOAD_ATTACHMENT_DIALOG),
  setMentionList: createAction(types.SET_MENTION_LIST),
  setIssueType: createAction(types.SET_ISSUE_TYPE),
  setPriorities: createAction(types.SET_PRIORITIES),
  setIssueCreatemeta: createAction(types.SET_ISSUE_CREATEMETA),
  showDeleteAttachmentConfirmDialog: createAction(
    types.SHOW_DELETE_ATTACHMENT_CONFIRM_DIALOG,
  ),
  editIssue: createAction(types.EDIT_ISSUE),
  editIssueStatus: createAction(types.EDIT_ISSUE_STATUS),
  setIssueFields: createAction(types.SET_ISSUES_FIELD),
  addNewProjectIssue: createAction(types.ADD_NEW_PROJECT_ISSUE),
  addNewSubTask: createAction(types.ADD_NEW_SUB_TASK),
};
