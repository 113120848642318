import { backEndApi } from '../utils';

const api = '/api/v1';
const inventoryPath = '/inventory';

const inventoryAPIs = {
  getFileCsvDownload: (body) =>
    backEndApi.post(`${api}${inventoryPath}/download`, body),
};

export default inventoryAPIs;
