import Avatar from '@atlaskit/avatar';
import Select, { components } from '@atlaskit/select';
import { makeStyles } from '@material-ui/styles';
import { siteAPIs } from '@root/services';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
    '& .assignee__menu': {
      minWidth: 70,
      '& img': {
        width: '20px',
        height: '20px',
      },
    },
  },
  assigneeWrapper: {
    '& img': {
      width: '20px',
      height: '20px',
    },
    '& div[class*="assignee__menu"]': {},
  },
  assignee: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    lineHeight: '26px',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    lineHeight: '26px',
    '& img': {
      width: '20px',
      height: '20px',
    },
  },
}));

const AtlassianUserSelect = ({
  siteId,
  onChange,
  defaultOptions,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const CustomIssueOption = ({ data, ...props }) => (
    <components.Option {...props}>
      <div className={classes.options}>
        {data.icon ? (
          <img src={data.icon} alt={data.label} />
        ) : (
          <Avatar size='small' />
        )}
        {data.label}
      </div>
    </components.Option>
  );
  const CustomIssueValueOption = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <div className={classes.value}>
        {data.icon ? (
          <img src={data.icon} alt={data.label} />
        ) : (
          <Avatar size='small' />
        )}
        {data.label}
      </div>
    </components.SingleValue>
  );

  const handleFetchOptions = async (inputEmail) => {
    let results = [];
    try {
      const { data } = await siteAPIs.searchAtlassianUsers(siteId, inputEmail);
      results = data.users;
    } catch (err) {
      // do nothing
    }
    results = results?.map((u) => ({
      ...u,
      label: u.emailAddress || u.displayName,
      value: u.accountId,
      icon: u.avatarUrls['24x24'] || u.avatarUrls['16x16'],
    }));
    return results;
  };
  const fetchUserOptions = async (inputEmail) => {
    let results = [];
    setIsLoading(true);
    results = await handleFetchOptions(inputEmail);
    setOptions(results);
    setIsLoading(false);
    return results;
  };
  const handleGetDefaultOptions = async () => {
    const results = await handleFetchOptions('');
    setOptions(results);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (defaultOptions) fetchUserOptions(inputValue);
      else if (inputValue.length > 1) fetchUserOptions(inputValue);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  useEffect(() => {
    if (defaultOptions) {
      handleGetDefaultOptions();
    }
  }, [defaultOptions]);

  return (
    <>
      <Select
        blurInputOnSelect
        classNamePrefix='assignee'
        className={classes.select}
        inputValue={inputValue}
        onInputChange={(_inputValue) => {
          setInputValue(_inputValue);
        }}
        isLoading={isLoading}
        loadingMessage={() => t('userManagement.loadingText')}
        noOptionsMessage={() => t('userManagement.noOptions')}
        isSearchable
        options={options}
        filterOption={() => options}
        cacheOptions
        defaultOptions
        onChange={(user) => {
          onChange(inputValue, user);
        }}
        placeholder={t('userManagement.searchPlaceHolderByName')}
        components={{
          Option: CustomIssueOption,
          SingleValue: CustomIssueValueOption,
        }}
        {...props}
      />
    </>
  );
};

export default AtlassianUserSelect;
