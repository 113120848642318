/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createReducer, current } from '@reduxjs/toolkit';
import actions from './actions';

const INITIAL_SITE = {
  loading: {},
  selectedSite: null,
  selectedItem: null,
  isUploadAttachmentDialogShowing: false,
  projectList: [],
  issueType: [],
  priorities: [],
  mentionList: [],
  issueTypeCreatemeta: [],
  attachment: {
    removal: {
      isAskingConfirmation: false,
      attachmentId: null,
    },
  },
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder
    .addCase(actions.setLoading, (state, action) => {
      const { loadingKey, value } = action.payload;
      state.loading[loadingKey] = value;
      return state;
    })
    .addCase(actions.setUserAssignable, (state, action) => {
      const { projectId, users, siteId } = action.payload;
      const project = state.projectList.find(
        (pr) => pr.id === projectId && pr.siteId === siteId,
      );
      project.userAssignable = users;
      return state;
    })
    .addCase(actions.setProjectList, (state, action) => {
      if (action.payload) state.projectList = action.payload;
      return state;
    })
    .addCase(actions.setTaskList, (state, action) => {
      const { values, projectId, totalTask, loadMore, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      if (prj) {
        if (!loadMore) {
          prj.tasks = values;
        } else {
          prj.tasks = [
            ...prj.tasks,
            ...values.filter(
              (task) => !prj.tasks.some((prjTask) => prjTask.id === task.id),
            ),
          ];
        }

        prj.totalTask = totalTask;
      }
      return state;
    })
    .addCase(actions.setSelectedSite, (state, action) => {
      const { siteId } = action.payload;
      state.selectedSite = siteId;
      return state;
    })
    .addCase(actions.setSelectedItem, (state, action) => {
      const { selectedItem } = action.payload;
      state.selectedItem = selectedItem;
      return state;
    })
    .addCase(actions.updateTaskExpand, (state, action) => {
      const { taskId, isExpand, projectId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const node = (prj || {}).tree.find((t) => t.id === taskId) || {};
      node.isExpand = isExpand;
      return state;
    })
    .addCase(actions.setStartDateField, (state, action) => {
      const { values, projectId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      if (prj) {
        prj.startDateField = values;
      }
      return state;
    })
    .addCase(actions.setTreeData, (state, action) => {
      const { values, projectId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      if (prj) {
        prj.tree = values;
      }
      return state;
    })
    .addCase(actions.addTaskComment, (state, action) => {
      const { values, projectId, taskId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = (prj || {}).tasks.find((t) => t.id === taskId) || {};
      task.fields.comment.comments = [...task.fields.comment.comments, values];
      return state;
    })
    .addCase(actions.deleteTaskComment, (state, action) => {
      const { commentId, projectId, taskId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = (prj || {}).tasks.find((t) => t.id === taskId) || {};
      task.fields.comment.comments = task.fields.comment.comments.filter(
        (c) => c.id !== commentId,
      );
      return state;
    })
    .addCase(actions.editTaskComment, (state, action) => {
      const { commentId, projectId, taskId, value, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = (prj || {}).tasks.find((t) => t.id === taskId) || {};
      const comment = task.fields.comment.comments.find(
        (c) => c.id === commentId,
      );
      comment.body = value;
      return state;
    })
    .addCase(actions.updateTask, (state, action) => {
      const { projectId, issueKeyOrId, fields, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task =
        (prj || {}).tasks.find(
          (t) => t.id === issueKeyOrId || t.key === issueKeyOrId,
        ) || {};
      task.fields = fields;
      return state;
    })
    .addCase(actions.deleteTask, (state, action) => {
      const { issueKeyOrId, projectId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      if (prj) {
        prj.tasks = prj.tasks.filter(
          (task) =>
            task &&
            task.id !== issueKeyOrId &&
            task.key !== issueKeyOrId &&
            task.fields.parent?.id !== issueKeyOrId &&
            task.fields.parent?.key !== issueKeyOrId,
        );
      }
      return state;
    })
    .addCase(actions.editTaskDescription, (state, action) => {
      const { value, projectId, taskId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = (prj || {}).tasks.find((t) => t.id === taskId) || {};
      task.fields.description = value;
      return state;
    })
    .addCase(actions.showUploadAttachmentDialog, (state, { payload }) => {
      state.isUploadAttachmentDialogShowing = payload;
      return state;
    })
    .addCase(actions.setIssueType, (state, action) => {
      const { value } = action.payload;
      state.issueType = value;
      return state;
    })
    .addCase(
      actions.showDeleteAttachmentConfirmDialog,
      (state, { payload }) => {
        state.attachment.removal = payload;
        return state;
      },
    )
    .addCase(actions.setPriorities, (state, action) => {
      const { value } = action.payload;
      state.priorities = value;
      return state;
    })
    .addCase(actions.setIssueCreatemeta, (state, action) => {
      const { value } = action.payload;
      state.issueTypeCreatemeta =
        value.projects.length > 0 ? value.projects[0].issuetypes : [];
      return state;
    })
    .addCase(actions.editIssue, (state, action) => {
      const { data, projectId, issueId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = (prj || {}).tasks.find((t) => t.id === issueId) || {};
      const key = Object.keys(data)[0];
      const value = Object.values(data)[0];
      task.fields[key] = value;
      return state;
    })
    .addCase(actions.editIssueStatus, (state, action) => {
      const { data, projectId, issueId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = (prj || {}).tasks.find((t) => t.id === issueId) || {};
      task.fields.status = data.to;
      return state;
    })
    .addCase(actions.setIssueFields, (state, action) => {
      const { data } = action.payload;
      state.issueFields = data;
      return state;
    })
    .addCase(actions.addNewProjectIssue, (state, action) => {
      const { data, projectId, siteId } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      prj.tasks.push(data);
      return state;
    })
    .addCase(actions.addNewSubTask, (state, action) => {
      const { data, projectId, siteId, parentKey } = action.payload;
      const prj = state.projectList.find(
        (s) => s.id === projectId && s.siteId === siteId,
      );
      const task = prj.tasks.find((t) => t.key === parentKey);
      task?.fields?.subtasks?.push(data);
      prj.tasks.push(data);
      return state;
    });
});
