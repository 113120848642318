const prefix = 'PROJECT/';

export const PROJECT_PROCESSING = `${prefix}PROJECT_PROCESSING`;
export const FETCH_PROJECT_TASK_SAGA = `${prefix}FETCH_PROJECT_TASK_SAGA`;
export const ADD_ISSUE_COMMENT_SAGA = `${prefix}ADD_ISSUE_COMMENT_SAGA`;
export const EDIT_ISSUE_COMMENT_SAGA = `${prefix}EDIT_ISSUE_COMMENT_SAGA`;
export const DELETE_ISSUE_COMMENT_SAGA = `${prefix}DELETE_ISSUE_COMMENT_SAGA`;
export const ADD_ISSUE_DESCRIPTION_SAGA = `${prefix}ADD_ISSUE_DESCRIPTION_SAGA`;
export const EDIT_ISSUE_DESCRIPTION_SAGA = `${prefix}EDIT_ISSUE_DESCRIPTION_SAGA`;
export const SET_TASK_LIST = `${prefix}SET_TASK_LIST`;
export const SET_PROJECT_LIST = `${prefix}SET_PROJECT_LIST`;
export const SET_USER_ASSIGNABLE = `${prefix}SET_USER_ASSIGNABLE`;
export const SET_SELECTED_SITE = `${prefix}SET_SELECTED_SITE`;
export const SET_SELECTED_ITEM = `${prefix}SET_SELECTED_ITEM`;
export const UPDATE_TASK = `${prefix}UPDATE_TASK`;
export const DELETE_TASK = `${prefix}DELETE_TASK`;
export const UPDATE_ISSUE_SAGA = `${prefix}UPDATE_ISSUE_SAGA`;
export const UPDATE_TASK_EXPAND_STATUS = `${prefix}UPDATE_TASK_EXPAND_STATUS`;
export const SET_START_DATE_FIELD = `${prefix}SET_START_DATE_FIELD`;
export const SET_TREE_DATA = `${prefix}SET_TREE_DATA`;
export const ADD_TASK_COMMENT = `${prefix}ADD_TASK_COMMENT`;
export const DELETE_TASK_COMMENT = `${prefix}DELETE_TASK_COMMENT`;
export const EDIT_TASK_COMMENT = `${prefix}EDIT_TASK_COMMENT`;
export const EDIT_TASK_DESCRIPTION = `${prefix}EDIT_TASK_DESCRIPTION`;
export const SHOW_UPLOAD_ATTACHMENT_DIALOG = `${prefix}SHOW_UPLOAD_ATTACHMENT_DIALOG`;
export const FETCH_METION_LIST_SAGA = `${prefix}FETCH_METION_LIST_SAGA`;
export const SET_MENTION_LIST = `${prefix}SET_MENTION_LIST`;
export const FETCH_ISSUE_TYPE_SAGA = `${prefix}FETCH_ISSUE_TYPE_SAGA`;
export const SET_ISSUE_TYPE = `${prefix}SET_ISSUE_TYPE`;
export const SET_PRIORITIES = `${prefix}SET_PRIORITIES`;
export const SET_ISSUE_CREATEMETA = `${prefix}SET_ISSUE_CREATEMETA`;
export const SHOW_DELETE_ATTACHMENT_CONFIRM_DIALOG = `${prefix}SHOW_DELETE_ATTACHMENT_CONFIRM_DIALOG`;
export const FETCH_ISSUE_PRIORITIES = `${prefix}FETCH_ISSUE_PRIORITIES`;
export const FETCH_ISSUE_CREATEMETA = `${prefix}FETCH_ISSUE_CREATEMETA`;
export const EDIT_ISSUE_FIELDS_SAGA = `${prefix}EDIT_ISSUE_FIELDS_SAGA`;
export const EDIT_ISSUE_STATUS_SAGA = `${prefix}EDIT_ISSUE_STATUS_SAGA`;
export const EDIT_ISSUE = `${prefix}EDIT_ISSUE`;
export const EDIT_ISSUE_STATUS = `${prefix}EDIT_ISSUE_STATUS`;
export const FETCH_FIELD_SAGA = `${prefix}FETCH_FIELD_SAGA`;
export const SET_ISSUES_FIELD = `${prefix}SET_ISSUES_FIELD`;
export const ADD_NEW_PROJECT_ISSUE = `${prefix}ADD_NEW_PROJECT_ISSUE`;
export const ADD_NEW_SUB_TASK = `${prefix}ADD_NEW_SUB_TASK`;
