/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import CheckboxIcon from '@atlaskit/icon/glyph/checkbox';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Visibility from '@atlaskit/icon/glyph/watch-filled';
import Spinner from '@atlaskit/spinner';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import mfaAPIs from '@root/services/mfaApis';
import { ACCESS_TOKEN_KEY, checkDate, REFRESH_TOKEN_KEY } from '@root/utils';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { organizationAPIs } from '@root/services';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../images/d_accel_yoko.png';
import { AuthContext } from './AuthProvider';
import SetupDeviceMFA from './SetupDeviceMFA';
import VerifyMfa from './VerifyMfa';

const useStyles = makeStyles(() => ({
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '332px',
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '192px',
  },

  loginTitle: {
    fontWeight: '600',
    fontSize: '24px',
    color: '#091E42',
    marginTop: '17px',
  },

  wordEmailField: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '60px',
    width: '100%',
  },

  messageCheckPass: {
    borderRadius: '0',
    position: 'fixed',
    top: '0',
    width: '100%',
    background: 'green',
    left: '0',
    color: '#fff',
    textAlign: 'left',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: '16px',
    display: 'flex',
    padding: '5px 15px',
    justifyContent: 'space-between',
  },

  wordEmailTitle: {
    fontWeight: '700',
    fontSize: '16px',
    color: '#6D6E6F',
  },
  input: {
    '& > input': {
      marginTop: '13px',
      color: '#000',
      border: '1px solid #C1C7D0',
      height: '40px',
      fontSize: '14px',
      background: ' #F5F5F5',
      boxSizing: 'border-box',
      fontWeight: 500,
      borderRadius: '20px',
      padding: '0 10px 1px 18px',
      '&:focus': {
        border: '1px solid #FAD100',
        background: '#F5F5F5 !important',
      },
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
    '& > select': {
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
          boxShadow: '0 0 0 2px #fad10042 !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
  },

  passwordField: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '28px',
    width: '100%',
  },
  passwordTitle: {
    fontWeight: '700',
    fontSize: '16px',
    color: '#6D6E6F',
  },
  eyesIcon: {
    position: 'absolute',
    right: '11px',
    top: '62%',
    '& span[data-testid="visibilityOff"]': {
      position: 'relative',
      '&:before': {
        content: '""',
        width: '26px',
        height: '2px',
        position: 'absolute',
        background: 'currentColor',
        transform: 'rotate(45deg)',
        top: '3px',
        left: '3px',
        transformOrigin: 'top left',
      },
      '&:after': {
        content: '""',
        width: '26px',
        height: '2px',
        position: 'absolute',
        background: '#fff',
        transform: 'rotate(45deg)',
        top: '1px',
        left: '4px',
        transformOrigin: 'top left',
      },
    },
  },
  forgotPassword: {
    display: 'flex',
    width: '100%',
    fontWeight: '500',
    fontSize: '14px',
    color: '#6D6E6F',
    marginTop: '10px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
      textDecoration: 'underline',
    },
  },
  loginButton: {
    cursor: 'pointer',
    background: 'linear-gradient(92.7deg, #EC6423 -20.42%, #FAD100 114.43%)',
    borderRadius: '20px',
    border: 'none',
    fontWeight: 700,
    fontSize: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '47px',
    alignItems: 'center',
    marginTop: '48px',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.85',
      color: '#FFFFFF',
    },
    color: '#F0FFF0',
  },
  errorMessageContainer: {
    color: '#D92929',
    fontSize: '16px',
    width: '100%',
    marginTop: '10px',
    display: 'flex',
  },
  errorMessage: {
    marginLeft: '5px',
  },
  callToActionContainer: {
    display: 'flex',
    fontSize: '14px',
    color: '#6D6E6F',
    margin: '10px 0',
  },
  linkAction: {
    fontWeight: '500',
    color: '#359be7',
    marginLeft: '5px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
      textDecoration: 'underline',
    },
  },
}));

const ScreenMode = {
  NORMAL_LOGIN: 'NORMAL_LOGIN',
  FIRST_LOGIN: 'FIRST_LOGIN',
  MFA_SETTING: 'MFA_SETTING',
  MFA_VERIFY: 'MFA_VERIFY',
};

const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    authenticate,
    completeNewPasswordChallenge,
    showSuccessfulChangePasswordNoti,
    setShowSuccessfulChangePasswordNoti,
  } = useContext(AuthContext);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [screenMode, setScreenMode] = useState(ScreenMode.NORMAL_LOGIN);
  const [newPassword, setNewPassword] = useState('');
  const [userAttr, setUserAttr] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [buttonTitle, setButtonTitle] = useState('auth.login.loginTitle');
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = location.state?.from;

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleClickShowPassWord = (event) => {
    event.preventDefault();
    setIsShowPassword(!isShowPassword);
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    navigate('/forgotPassword', { replace: true });
  };

  const handleSignUpNow = (event) => {
    event.preventDefault();
    navigate('/signup', { replace: true });
  };

  const checkHasOrganizationApiKey = async () => {
    const response = await organizationAPIs.getAllOrganizationAPIKeys();
    const orgs = response.data.organizations;
    const hasOrganizationApiKey = orgs.length && orgs.length > 0;
    return hasOrganizationApiKey;
  };

  const handleAfterLogin = async (data) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken.jwtToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken.token);
    const userId = data.accessToken.payload.sub;
    const { data: mfaReponse } = await mfaAPIs.getMfaStatus(userId);
    const userMfaPreference = mfaReponse?.raw;
    if (!userMfaPreference?.mfaStatus && userMfaPreference?.mfaRequired) {
      // Show MFA setting
      setScreenMode(ScreenMode.MFA_SETTING);
    } else {
      // normal login
      const urlParams = new URLSearchParams(window.location.search);
      if (previousLocation?.pathname === '/recovery-code-management') {
        window.location.href = `${window.origin}${previousLocation?.pathname}${previousLocation?.search}`;
      } else if (urlParams.get('state')) {
        window.location.href = urlParams.get('state');
      } else {
        const hasOrganizationApiKey = await checkHasOrganizationApiKey();
        const baseUrl = hasOrganizationApiKey
          ? `${window.origin}`
          : `${window.origin}/register-organization-api-key`;
        window.location.href = `${baseUrl}${window.location.search}`;
      }
    }
    setIsLoading(false);
  };

  const handleLogin = () => {
    setIsLoading(true);
    newRelicUtils.addPageAction(PAGE_ACTION.login.in, {
      userEmail: email,
      userId: null,
    });
    authenticate(email, password, '')
      .then(async (result) => {
        if (result.newPasswordRequired) {
          delete result.data.email_verified;
          delete result.data.email;
          setUserAttr(result.data);
          setLoggedInUser(result.user);
          setScreenMode(ScreenMode.FIRST_LOGIN);
          setIsLoading(false);
        } else if (result.totpRequired) {
          setUserAttr(result.user);
          setScreenMode(ScreenMode.MFA_VERIFY);
        } else {
          await handleAfterLogin(result.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (err.code === 'UserNotConfirmedException') {
          setErrorMessage(t('auth.login.userNotConfirmedException'));
        } else if (
          err.code === 'NotAuthorizedException' ||
          !regex.test(email)
        ) {
          if (err.message === 'Password attempts exceeded') {
            setErrorMessage(t('auth.login.attemptsExceeded'));
          } else {
            setErrorMessage(t('auth.login.incorrectAccountError'));
          }
        } else {
          setErrorMessage(t('auth.login.serverError'));
        }
      });
  };

  const handleChangePassword = () => {
    setIsLoading(true);
    newRelicUtils.addPageAction(PAGE_ACTION.login.changePassword, {
      userEmail: email,
      isFirstLogin: true,
      userId: null,
    });
    completeNewPasswordChallenge(loggedInUser, newPassword, userAttr)
      .then((data) => {
        handleAfterLogin(data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.code === 'InvalidPasswordException') {
          setErrorMessage(t('auth.login.passwordFormatError'));
        }
      });
  };

  const closeMessage = () => {
    setShowSuccessfulChangePasswordNoti(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (screenMode === ScreenMode.NORMAL_LOGIN) {
      handleLogin();
    }
    if (screenMode === ScreenMode.FIRST_LOGIN) {
      handleChangePassword();
    }
  };

  useEffect(() => {
    if (screenMode === ScreenMode.NORMAL_LOGIN) {
      setButtonTitle('auth.login.login');
    }
    if (screenMode === ScreenMode.FIRST_LOGIN) {
      setButtonTitle('auth.login.changePassword');
    }
  }, [screenMode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSuccessfulChangePasswordNoti(false);
    }, 4000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (screenMode === ScreenMode.MFA_SETTING)
    return (
      <SetupDeviceMFA
        backToLogInScreen={() => {
          setScreenMode(ScreenMode.NORMAL_LOGIN);
        }}
        userEmail={email}
      />
    );

  if (screenMode === ScreenMode.MFA_VERIFY)
    return <VerifyMfa user={userAttr} />;

  return (
    <>
      <form onSubmit={onSubmit} className={classes.loginForm}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt='Daccel' src={logo} />
        </div>
        <span className={classes.loginTitle}>{t('auth.login.loginTitle')}</span>
        {screenMode === ScreenMode.NORMAL_LOGIN && (
          <>
            <div className={classes.wordEmailField}>
              <span className={classes.wordEmailTitle}>
                {t('auth.login.workEmailFormTitle')}
              </span>
              <Input
                onFocus={() => setErrorMessage(null)}
                required
                disabled={isLoading}
                className={classes.input}
                disableUnderline
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={t('auth.login.workEmailPlaceholder')}
                onBlur={() => {
                  setEmail(email.trim());
                }}
              />
            </div>
            <div className={classes.passwordField}>
              <span className={classes.passwordTitle}>
                {t('auth.login.passwordTitle')}
              </span>
              <Input
                onFocus={() => setErrorMessage(null)}
                required
                disabled={isLoading}
                type={isShowPassword ? 'text' : 'password'}
                className={classes.input}
                disableUnderline
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={() => {
                  setPassword(password.trim());
                }}
                placeholder={t('auth.login.passwordPlaceholder')}
                endAdornment={
                  <InputAdornment position='end' className={classes.eyesIcon}>
                    <IconButton onClick={handleClickShowPassWord} size='small'>
                      {isShowPassword ? (
                        <Visibility />
                      ) : (
                        <Visibility testId='visibilityOff' />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            <div
              onClick={handleForgotPassword}
              className={classes.forgotPassword}
            >
              {t('auth.login.forgotPassword')}
            </div>
          </>
        )}

        {screenMode === ScreenMode.FIRST_LOGIN && (
          <>
            <div className={classes.passwordField}>
              <span className={classes.passwordTitle}>
                {t('auth.login.newPasswordTitle')}
              </span>
              <Input
                onFocus={() => setErrorMessage(null)}
                required
                disabled={isLoading}
                type={isShowPassword ? 'text' : 'password'}
                className={classes.input}
                disableUnderline
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
                placeholder={t('auth.login.newPasswordPlaceholder')}
                endAdornment={
                  <InputAdornment position='end' className={classes.eyesIcon}>
                    <IconButton onClick={handleClickShowPassWord} size='small'>
                      {isShowPassword ? (
                        <Visibility />
                      ) : (
                        <Visibility testId='visibilityOff' />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </>
        )}

        {errorMessage && (
          <div className={classes.errorMessageContainer}>
            <WarningIcon />
            <span className={classes.errorMessage}>{errorMessage}</span>
          </div>
        )}
        <button
          type='submit'
          className={classes.loginButton}
          disabled={isLoading}
          style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
        >
          {isLoading ? <Spinner /> : t(buttonTitle)}
        </button>
        <div className={classes.callToActionContainer}>
          <div>{t('auth.login.callToSignUp')}</div>
          <div className={classes.linkAction} onClick={handleSignUpNow}>
            {t('auth.signup.signup')}
          </div>
        </div>
        {showSuccessfulChangePasswordNoti && (
          <div className={classes.messageCheckPass}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckboxIcon
                size='large'
                primaryColor='#FFFFFF'
                secondaryColor='#008000'
              />
              <span style={{ marginLeft: '5px' }}>
                {t('auth.forgotPassword.changePassSuccess')}
              </span>
            </div>
            <div>
              <button
                type='button'
                onClick={closeMessage}
                style={{
                  background: 'green',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <EditorCloseIcon primaryColor='#FFFFFF' />
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default Login;
