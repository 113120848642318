// eslint-disable-next-line import/prefer-default-export
export const PAGE_ACTION = {
  login: {
    in: '[Login] Login',
    out: '[Login] Logout',
    changePassword: '[Login] Change Password',
    verifyMFA: '[Login] Verify MFA',
    mfaRegister: '[Login] Register MFA device',
  },
  signup: {
    signup: '[Signup] Signup',
    verifyEmail: '[Signup] Verify Email',
  },
  security: {
    view: '[Security] View Security Page',
    filter: '[Security] Filter Security Items',
    renew: {
      project: '[Security] Project: Renew Data',
      dashboard: '[Security] Dashboard: Renew Data',
      filter: '[Security] Filter: Renew Data',
      space: '[Security] Space: Renew Data',
    },
    linkGeneralConfig:
      '[Security] Public sharing: Click Show General Configuration',
    linkShowUser: '[Security] Project: Click Show users',
    linkPermission: {
      project: '[Security] Project: Click View permission scheme',
      space: '[Security] Space: Click View permission scheme',
    },
    changePrivate: {
      dashboard: '[Security] Dashboard: Change Viewer to Private',
      filter: '[Security] Filter: Change Viewer to Private',
    },
    changeOrg: {
      dashboard: '[Security] Dashboard: Change Viewer to Organization',
      filter: '[Security] Filter: Change Viewer to Organization',
    },
  },
  auditLog: {
    view: '[Audit Log] View Audit Log Page',
    download: '[Audit Log] Download Audit Log',
  },
  statistic: {
    view: '[Product Statistic] View product Statistic Page',
    // siteIds, rangeTime
    filter: '[Product Statistic] Filter by multiple sites',
  },
  userInventory: {
    view: '[User Inventory] View User Inventory Page',
    // siteIds, rangeTime
    filter: '[User Inventory] Filter by multiple sites',
    export: '[User Inventory] Export file User Inventory',
    revoke: '[User Inventory] Revoke user',
  },
  userActivity: {
    view: '[User Activity] View User Activity Page',
    filter: '[User Activity] Filter by multiple sites',
    updated: '[User Activity] Updated setting maximum number of users',
  },
  orgApiKey: {
    open: '[Org API Key] Open organization API key',
    cannel: '[Org API Key] Close organization API key',
    addModal: '[Org API Key] Open Model Add organization API key',
    save: '[Org API Key] Save organization API key',
    edit: '[Org API Key] Edit organization API key',
    update: '[Org API Key] Update organization API key',
    remove: '[Org API Key] Delete organization API key',
    test: '[Org API Key] Test organization API key',
  },
  userManagement: {
    open: '[User Management] Open user management',
    cannel: '[User Management] Close user management',
    invite: '[User Management] Invite users',
    reinvite: '[User Management] Re-invite users',
    remove: '[User Management] Remove users',
    changeSite: '[User Management] Change site',
    turnOffMFARequire: '[User Management] Turn off MFA Require',
    resetMFADevice: '[User Management] Reset MFA device',
    turnOnMFARequire: '[User Management] Turn on MFA Require',
  },
  auditLogSetting: {
    open: '[Audit Log Setting] Open Audit Log Setting',
    cannel: '[Audit Log Setting] Close Audit Log Setting',
    save: '[Audit Log Setting] Save Audit Log Setting',
    test: '[Audit Log Setting] Test Audit Log Setting',
    off: '[Audit Log Setting] Turn off Audit Log Setting',
    enableTransferDataSuccess:
      '[Audit Log Setting] Turn on Transfer data option successfully',
    enableTransferDataFailed:
      '[Audit Log Setting] Turn on Transfer data option failed',
    setting: '[Audit Log Setting] Setting Audit Log Setting',
    viewTransferStatus: '[Audit Log Setting] View transfer status',
    viewTransferStatusDetail: '[Audit Log Setting] View transfer status detail',
  },
  ipAllowlist: {
    view: '[IP allowlist] View IP allowlist Page',
    changeOrg: '[IP Allowlist] Change Organization',
    export: '[IP Allowlist] Export file IP Allowlist',
    import: '[IP Allowlist] Import file IP Allowlist',
    viewImport: '[IP Allowlist] View Import file IP Allowlist',
    exportDefault: '[IP Allowlist] Export template create new file',
    update: '[IP Allowlist] Update IP Allowlist',
    cannel: '[IP Allowlist] Cancel Update IP Allowlist',
  },
  notification: {
    view: '[Notification] View Notification Page',
    edit: '[Notification] Edit notification setting',
    save: '[Notification] Save notification setting',
  },
  installationStatus: {
    viewPage: '[D-Accel installation] View D-Accel Installation Status Page',
    changeOrg: '[D-Accel installation] Change Organization',
  },
};
