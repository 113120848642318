/* eslint-disable no-param-reassign */
import moment from 'moment';
import { CIDR_REGEX } from './constant';

export const importAll = (r, hasDefault) =>
  r.keys().map((e) => {
    const path = e.split('/');
    return {
      name: path.slice(-1)[0].split('.')[0].toUpperCase(),
      key: path.reverse()[1],
      data: hasDefault ? r(e).default : r(e),
    };
  });
export const uniqueArray = (array) =>
  array.filter((v, i, a) => a.indexOf(v) === i);
export const uniqueArrayObject = (array, key) =>
  Array.from(new Set(array.map((a) => a[key]))).map((n) =>
    array.find((a) => a[key] === n),
  );

export const getToken = () =>
  new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    AP.context.getToken((token) => {
      resolve(token);
    });
  });

export const noop = () => {};
export const isNotEmptyArray = (array) =>
  Array.isArray(array) && array.length > 0;
export const toQueryString = (obj) =>
  Object.entries(obj).reduce((acc, [key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((e) => {
        acc += `${(acc ? '&' : '') + key}=${e}`;
      });
    } else if (val !== null && val !== '' && val !== undefined) {
      acc += `${(acc ? '&' : '') + key}=${val}`;
    }
    return acc;
  }, '');

export const removeDuplicate = (arr) =>
  arr?.filter((value, index, self) => self && self.indexOf(value) === index);

export const isValidCIDR = (cird) =>
  !(!CIDR_REGEX.ip_v4.test(cird) && !CIDR_REGEX.ip_v6.test(cird));

export function checkDate(date) {
  const numberOfDays = moment(date).diff(moment(), 'days');
  return numberOfDays >= 0;
}
