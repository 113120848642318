/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Select, { components } from '@atlaskit/select';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { isNotEmptyArray, isSiteDisable } from '@root/utils';

const useStyle = makeStyles((theme) => ({
  optionDisable: {},
  options: {},
  value: {},
}));
const SiteListSelectField = ({
  options,
  defaultSiteId,
  onChange = () => {},
  ...props
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [siteSearching, setSiteSearching] = React.useState(null);
  const CustomIssueOption = ({ data, ...props }) => {
    const site = options.find((i) => i.siteId === data.value);
    const isDisable = isSiteDisable(site);
    return (
      <components.Option {...props} style={{ display: 'none' }}>
        <div className={isDisable ? classes.optionDisable : classes.options}>
          {isDisable ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon primaryColor='#E5686F' />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 4,
                  }}
                >
                  <div>{data.label}</div>
                  <div style={{ color: '#E5686F', fontSize: 10 }}>
                    {t('common.licenseOutdate')}
                  </div>
                </div>
              </div>
            </>
          ) : (
            data.label
          )}
        </div>
      </components.Option>
    );
  };

  const CustomIssueValueOption = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <div className={classes.value}>{data.label}</div>
    </components.SingleValue>
  );

  useEffect(() => {
    if (isNotEmptyArray(options)) {
      const siteItem = options?.find(
        (i) =>
          i.value === defaultSiteId &&
          (i.isJiraLicense || i.isConfluenceLicense),
      );
      if (siteItem) setSiteSearching(siteItem);
      else if (!defaultSiteId) {
        const firstLicenseSite = options?.find((i) => !isSiteDisable(i));
        setSiteSearching(firstLicenseSite);
        if (firstLicenseSite) onChange(firstLicenseSite);
      }
    }
  }, [JSON.stringify(options), defaultSiteId]);

  return (
    <Select
      blurInputOnSelect
      value={siteSearching}
      components={{
        Option: CustomIssueOption,
        SingleValue: CustomIssueValueOption,
      }}
      isOptionDisabled={(option, selectValue) => {
        const site = options.find((i) => i.siteId === option.value);
        const isDisable = isSiteDisable(site);
        return isDisable;
      }}
      onChange={(site) => {
        setSiteSearching(site);
        onChange(site);
      }}
      options={options}
      placeholder={t('activity.selectSitePlaceholder')}
      {...props}
    />
  );
};
export default SiteListSelectField;
