import LoadingButton from '@atlaskit/button/loading-button';
import Button from '@atlaskit/button/standard-button';
import { Field } from '@atlaskit/form';
import Modal, {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { siteAPIs } from '@root/services';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import i18n from '@root/translations/i18n';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { InlineMessage } from '@root/components/common/message';
import { actions } from '../../redux';
import { noop } from '../../utils/common';
import { ALERT_TYPE } from '../common/MessageSnackbar';
import UserManagerContext from './UserManagerContext';
import AtlassianUserSelect from './AtlassianUserSelect';

const validateEmail = (email) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const linkJa =
  'https://support.atlassian.com/ja/confluence-cloud/docs/configure-user-email-visibility/';
const linkEn =
  'https://support.atlassian.com/confluence-cloud/docs/configure-user-email-visibility/';

export default function InviteUserDialog({ onClose = noop }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = i18n.language;

  const { loadUsers, siteId, siteUsers } = useContext(UserManagerContext);
  const [email, setEmail] = useState('');
  const [atlassianUser, setAtlassianUser] = useState(null);
  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [displayEmailInput, setDisplayEmailInput] = useState(false);

  const handleErrorMessage = (inviteUserError) => {
    let errorMessage = 'snackbar.inviteSiteUserFailed';
    if (
      inviteUserError?.data?.message ===
      'Email address or Atlassian account Id is exist'
    ) {
      errorMessage = 'userManagement.dialog.inviteUser.existUserErr';
    }
    dispatch(
      actions.openSnackbar({
        open: true,
        type: ALERT_TYPE.error,
        messageKey: errorMessage,
      }),
    );
  };
  const handleInvite = async (email) => {
    try {
      setIsInvitingUser(true);
      const response = await siteAPIs.inviteUser(siteId, {
        email,
        accountId: atlassianUser.accountId,
      });
      if (response.status === 200) {
        await loadUsers();
        setIsInvitingUser(false);
        setDisplayEmailInput(false);
        setEmail('');
        dispatch(
          actions.openSnackbar({
            open: true,
            type: ALERT_TYPE.success,
            messageKey: 'snackbar.inviteSiteUserOk',
          }),
        );
        onClose();
      } else {
        setIsInvitingUser(false);
        handleErrorMessage(response);
      }
    } catch (inviteUserError) {
      setIsInvitingUser(false);
      handleErrorMessage(inviteUserError.response);
    }
  };
  const emailInvalid = !validateEmail(email);
  const emailExisted = !!siteUsers.find(
    (user) => user?.email?.toLowerCase() === email.toLowerCase().trim(),
  );

  const inviteUser = async () => {
    newRelicUtils.addPageAction(PAGE_ACTION.userManagement.invite, {
      siteIds: siteId,
      targetEmail: email,
    });
    if (!atlassianUser.emailAddress) {
      if (displayEmailInput) {
        // in case user input email address and press invite
        if (emailInvalid) {
          dispatch(
            actions.openSnackbar({
              open: true,
              type: ALERT_TYPE.error,
              messageKey: 'organizationSettings.emailInvalid',
            }),
          );
        } else if (emailExisted) {
          dispatch(
            actions.openSnackbar({
              open: true,
              type: ALERT_TYPE.error,
              messageKey: 'organizationSettings.emailExisted',
            }),
          );
        } else {
          handleInvite(email);
        }
      }
    } else {
      handleInvite(atlassianUser.emailAddress);
    }
  };

  return (
    <ModalTransition>
      <Modal onClose={onClose} width='large'>
        <form onSubmit={(e) => e.preventDefault()}>
          <ModalHeader>
            <ModalTitle>
              {t('userManagement.dialog.inviteUser.title')}
            </ModalTitle>
          </ModalHeader>
          <div style={{ padding: '0 24px' }}>
            <Field
              id='search'
              name='search'
              // label={t('userManagement.dialog.inviteUser.inviteUserLabel')}
            >
              {({ fieldProps }) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <AtlassianUserSelect
                      siteId={siteId}
                      onChange={(inputEmail, user) => {
                        if (!user?.emailAddress) {
                          setDisplayEmailInput(true);
                        } else setDisplayEmailInput(false);
                        setAtlassianUser(user);
                      }}
                    />
                    {displayEmailInput && (
                      <Textfield
                        {...fieldProps}
                        placeholder={t(
                          'userManagement.dialog.inviteUser.emailPlaceholder',
                        )}
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        isInvalid={emailInvalid}
                      />
                    )}
                  </div>
                  <InlineMessage variant='warning' title=''>
                    {t('userManagement.dialog.inviteUser.existUserEmailNote')}
                    <a
                      href={currentLang === 'en' ? linkEn : linkJa}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t(
                        'userManagement.dialog.inviteUser.existUserEmailNoteReadmore',
                      )}
                    </a>
                  </InlineMessage>
                  <LoadingButton
                    style={{
                      marginTop: '0.5rem',
                    }}
                    isLoading={isInvitingUser}
                    appearance='primary'
                    isDisabled={!atlassianUser?.accountId}
                    onClick={() => {
                      inviteUser();
                    }}
                  >
                    {t('userManagement.dialog.inviteUser.inviteButtonLabel')}
                  </LoadingButton>
                </div>
              )}
            </Field>
          </div>
          <ModalFooter>
            <Button appearance='subtle' onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </ModalTransition>
  );
}
