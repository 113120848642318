/* eslint-disable no-await-in-loop */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ALERT_TYPE } from '../../components/common/MessageSnackbar';
import { siteAPIs } from '../../services';
import atlasAPIs from '../../services/atlasApis';
import * as appTypes from '../app/types';
import actions from './actions';
import * as types from './types';

const getAllTasksByIds = async (siteId, issueIds, fieldcustom) => {
  let issues = [];
  for (let i = 0; i < issueIds.length; i += 50) {
    const sliceIds = issueIds.slice(i, i + 50);
    const jqlEncode = encodeURIComponent(`issue in (${sliceIds.join(',')})`);
    const optsD = {
      headers: {
        siteid: siteId,
        relayapi: `/rest/api/3/search/?jql=${jqlEncode}&maxResults=100&fields=${fieldcustom},duedate,assignee,attachment,comment,issuetype,project,status,parent,subtasks,summary,description`,
        relaymethod: 'GET',
      },
    };
    const raw = await atlasAPIs.proxyApi(optsD);
    issues = issues.concat(raw.data.issues);
  }
  return issues;
};

const getAllTasks = async (
  siteId,
  projectId,
  page,
  pageSize,
  nameSearch,
  filter,
  currentUser,
  issueTypeList,
  sortBy,
) => {
  const maxResults = Number(pageSize);
  // const jql = `project=${projectId} AND issuetype != Epic`;
  let jqlQuery = filter.length
    ? `project=${projectId}&${filter}`
    : `project=${projectId}`;
  if (nameSearch && nameSearch.length) {
    jqlQuery = `${jqlQuery}&text~"${nameSearch}"`;
  }
  const jqlEncode =
    sortBy && sortBy.key
      ? encodeURIComponent(`${jqlQuery} ORDER BY ${sortBy.key} ${sortBy.type}`)
      : encodeURIComponent(`${jqlQuery} ORDER BY RANK ASC`);
  const optsField = {
    headers: {
      siteid: siteId,
      relayapi: '/rest/api/3/field',
      relaymethod: 'GET',
    },
  };
  const rawField = await atlasAPIs.proxyApi(optsField);
  const fieldcustomStartdate = rawField?.data.filter(
    (field) => field.name === 'Start date',
  );
  const fieldcustom = fieldcustomStartdate[0].id;
  const optsD = {
    headers: {
      siteid: siteId,
      relayapi: `/rest/api/3/search/?jql=${jqlEncode}&maxResults=${maxResults}&fields=${fieldcustom},duedate,assignee,attachment,comment,issuetype,project,status,parent,subtasks,summary,description&startAt=${
        page * maxResults
      }`,
      relaymethod: 'GET',
    },
  };
  const raw = await atlasAPIs.proxyApi(optsD);
  const subTaskIds = raw.data.issues.reduce((subTask, issue) => {
    if (issue.fields.subtasks?.length)
      return subTask.concat(issue.fields.subtasks.map((task) => task.id));
    return subTask;
  }, []);
  const subtasks = await getAllTasksByIds(siteId, subTaskIds, fieldcustom);
  const issueIds = raw.data.issues.map((i) => i.id).concat(subTaskIds);

  const checkPermissionOption = {
    headers: {
      siteid: siteId,
      relayapi: '/rest/api/3/permissions/check',
      relaymethod: 'POST',
    },
  };
  const checkPermissionBody = {
    accountId: currentUser.atlassianAccountId,
    projectPermissions: [
      {
        projects: [projectId],
        permissions: ['BROWSE_PROJECTS'],
      },
      {
        projects: [projectId],
        issues: issueIds,
        permissions: ['DELETE_ISSUES'],
      },
      {
        projects: [projectId],
        issues: issueIds,
        permissions: ['EDIT_ISSUES'],
      },
      {
        projects: [projectId],
        issues: issueIds,
        permissions: ['CREATE_ATTACHMENTS'],
      },
      {
        projects: [projectId],
        issues: issueIds,
        permissions: ['ADD_COMMENTS'],
      },
    ],
  };
  const checkPermissionRes = await atlasAPIs.proxyApi(
    checkPermissionOption,
    checkPermissionBody,
  );
  if (checkPermissionRes.status !== 200) {
    return null;
  }
  const { projectPermissions } = checkPermissionRes.data;
  const hasBrowsePermission = projectPermissions.find(
    (projectPermission) =>
      projectPermission.permission === 'BROWSE_PROJECTS' &&
      projectPermission.projects.length > 0,
  );
  if (!hasBrowsePermission) {
    return null;
  }
  const deletePermissionIssueIds = projectPermissions
    .find(
      (projectPermission) => projectPermission.permission === 'DELETE_ISSUES',
    )
    .issues.map(String);
  const editPermissionIssueIds = projectPermissions
    .find((projectPermission) => projectPermission.permission === 'EDIT_ISSUES')
    .issues.map(String);

  const addCommentPermissionIssueIds = projectPermissions
    .find(
      (projectPermission) => projectPermission.permission === 'ADD_COMMENTS',
    )
    .issues.map(String);

  const createAttachmentPermissionIssueIds = projectPermissions
    .find(
      (projectPermission) =>
        projectPermission.permission === 'CREATE_ATTACHMENTS',
    )
    .issues.map(String);

  // check edit, delete issue permission
  const values = raw.data.issues.map((issue) => {
    const haveDeletePermission = deletePermissionIssueIds.includes(issue.id);
    const haveEditPermission = editPermissionIssueIds.includes(issue.id);
    const haveAddCommentPermission = addCommentPermissionIssueIds.includes(
      issue.id,
    );
    const haveCreateAttachmentPermission =
      createAttachmentPermissionIssueIds.includes(issue.id);
    return {
      ...issue,
      haveCreateAttachmentPermission,
      haveAddCommentPermission,
      haveDeletePermission,
      haveEditPermission,
    };
  });
  // check edit, delete issue permission

  const result = {
    values,
    totalTask: raw.data.total,
    subtasks: subtasks.map((issue) => {
      const haveDeletePermission = deletePermissionIssueIds.includes(issue.id);
      const haveEditPermission = editPermissionIssueIds.includes(issue.id);
      return {
        ...issue,
        haveDeletePermission,
        haveEditPermission,
      };
    }),
  };
  return result;
};
const mergedTask = (issues, subtasks) => {
  const parentTasks = issues.map((issue) => ({ id: issue.id, level: 0 }));
  const childTasks = subtasks.map((issue) => ({
    id: issue.id,
    level: 1,
    parentId: issue.fields.parent.id,
  }));

  childTasks.forEach((c) => {
    const { parentId } = c;
    const pT = parentTasks.find((p) => p.id === parentId);
    if (pT) {
      pT.children = (pT.children || []).concat(c);
      pT.isExpand = true;
    }
  });
  return parentTasks;
};

export function* fetchProjectTasksSaga() {
  yield takeLatest(
    types.FETCH_PROJECT_TASK_SAGA,
    function* fetchProjectTasks({ payload }) {
      try {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'taskListLoading', value: true },
        });
        const {
          siteId,
          projectId,
          page,
          pageSize,
          nameSearch,
          filter,
          issueTypeList,
          loadMore,
          sortBy,
        } = payload;
        const currentUser = yield select((state) => state.app.currentUser);

        // check has BROWSER PERMISSION first
        const currentAccountId = yield select(
          (state) => state.app.currentUser.atlassianAccountId,
        );
        const opts = {
          headers: {
            siteid: siteId,
            relayapi: '/rest/api/3/permissions/check',
            relaymethod: 'POST',
          },
        };
        const bodyParam = {
          globalPermissions: ['ADMINISTER'],
          accountId: currentAccountId,
          projectPermissions: [
            {
              projects: [projectId],
              permissions: ['BROWSE_PROJECTS'],
            },
          ],
        };
        const checkBrowserPermissionRaw = yield call(
          atlasAPIs.proxyApi,
          opts,
          bodyParam,
        );
        const { data = {} } = checkBrowserPermissionRaw;
        const hasBrowsePermission =
          Number(projectId) === data?.projectPermissions[0]?.projects[0];
        if (hasBrowsePermission) {
          const obj = yield call(
            getAllTasks,
            siteId,
            projectId,
            page,
            pageSize,
            nameSearch,
            filter,
            currentUser,
            issueTypeList,
            sortBy,
          );
          const { totalTask, values, subtasks } = obj;
          const treeData = values ? mergedTask(values, subtasks) : null;
          yield put(
            actions.setTaskList({
              values: values.concat(subtasks),
              projectId,
              totalTask,
              loadMore,
              siteId,
            }),
          );
          yield put(
            actions.setTreeData({ values: treeData, projectId, siteId }),
          );
          yield put({
            type: types.PROJECT_PROCESSING,
            payload: { loadingKey: 'taskListLoading', value: false },
          });
        } else {
          yield put({
            type: appTypes.OPEN_SNACK_BAR,
            payload: {
              open: true,
              type: ALERT_TYPE.warning,
              messageKey: 'snackbar.projectPermissionDenied',
            },
          });
        }
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'taskListLoading', value: false },
        });
      } catch (error) {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'taskListLoading', value: false },
        });
        console.log('----fetchProjectTasksSaga error----', error);
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
export function* addIssueDescriptionSaga() {
  yield takeLatest(
    types.ADD_ISSUE_DESCRIPTION_SAGA,
    function* addIssueDescription({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { taskId, value, projectId, siteId } = payload;
        const optsD = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issue/${taskId}`,
            relaymethod: 'PUT',
          },
        };
        const body = {
          fields: {
            description: value,
          },
        };
        yield put(
          actions.editTaskDescription({ value, projectId, taskId, siteId }),
        );
        yield call(atlasAPIs.proxyApi, optsD, body);
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
export function* addIssueCommentSaga() {
  yield takeLatest(
    types.ADD_ISSUE_COMMENT_SAGA,
    function* addIssueComment({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { taskId, value, projectId, siteId } = payload;
        const optsD = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issue/${taskId}/comment`,
            relaymethod: 'POST',
          },
        };
        const raw = yield call(atlasAPIs.proxyApi, optsD, {
          body: value,
          visibility: null,
        });
        const { data } = raw;
        yield put(
          actions.addTaskComment({ values: data, projectId, taskId, siteId }),
        );
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
export function* editIssueCommentSaga() {
  yield takeLatest(
    types.EDIT_ISSUE_COMMENT_SAGA,
    function* editIssueComment({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { taskId, projectId, siteId, commentId, value } = payload;
        const optsD = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issue/${taskId}/comment/${commentId}`,
            relaymethod: 'PUT',
          },
        };
        yield put(
          actions.editTaskComment({
            commentId,
            projectId,
            taskId,
            value,
            siteId,
          }),
        );
        yield call(atlasAPIs.proxyApi, optsD, {
          body: value,
          visibility: null,
        });
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
export function* deleteIssueCommentSaga() {
  yield takeLatest(
    types.DELETE_ISSUE_COMMENT_SAGA,
    function* deleteIssueComment({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { taskId, projectId, siteId, commentId } = payload;
        const optsD = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issue/${taskId}/comment/${commentId}`,
            relaymethod: 'DELETE',
          },
        };
        yield put(
          actions.deleteTaskComment({ commentId, projectId, taskId, siteId }),
        );
        yield call(atlasAPIs.proxyApi, optsD);
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
export function* fetchIssueTypeSaga() {
  yield takeLatest(
    types.FETCH_ISSUE_TYPE_SAGA,
    function* fetchIssueType({ payload }) {
      try {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issueTypeLoading', value: true },
        });
        const { siteId, projectId } = payload;
        const opts = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issuetype/project/?projectId=${projectId}`,
            relaymethod: 'GET',
          },
        };
        const issueTypeRaw = yield call(atlasAPIs.proxyApi, opts);
        const { data } = issueTypeRaw;
        yield put(actions.setIssueType({ value: data }));
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issueTypeLoading', value: false },
        });
      } catch (error) {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issueTypeLoading', value: false },
        });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
export function* fetchIssuePrioritiesSaga() {
  yield takeLatest(
    types.FETCH_ISSUE_PRIORITIES,
    function* fetchIssueType({ payload }) {
      try {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issuePriorityLoading', value: true },
        });
        const { siteId } = payload;
        const opts = {
          headers: {
            siteid: siteId,
            relayapi: '/rest/api/3/priority',
            relaymethod: 'GET',
          },
        };
        const issueTypeRaw = yield call(atlasAPIs.proxyApi, opts);
        const { data } = issueTypeRaw;
        yield put(actions.setPriorities({ value: data }));
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issuePriorityLoading', value: false },
        });
      } catch (error) {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issuePriorityLoading', value: false },
        });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}

export function* fetchIssueCreatemetaSaga() {
  yield takeLatest(
    types.FETCH_ISSUE_CREATEMETA,
    function* fetchIssueCreatemeta({ payload }) {
      try {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issueCreatemetaLoading', value: true },
        });
        const { siteId, projectId } = payload;

        const createmetaDataRaw = yield call(
          siteAPIs.getIssueCreatemeta,
          siteId,
          [projectId],
        );
        const { data } = createmetaDataRaw;
        yield put(actions.setIssueCreatemeta({ value: data }));
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issueCreatemetaLoading', value: false },
        });
      } catch (error) {
        yield put({
          type: types.PROJECT_PROCESSING,
          payload: { loadingKey: 'issueCreatemetaLoading', value: false },
        });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}

export function* addIssueSaga() {
  yield takeLatest(
    types.EDIT_ISSUE_FIELDS_SAGA,
    function* addIssueDescription({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { issueId, data, projectId, siteId } = payload;
        const optsD = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issue/${issueId}`,
            relaymethod: 'PUT',
          },
        };
        const body = { fields: data };
        yield put(actions.editIssue({ data, projectId, issueId, siteId }));
        yield call(atlasAPIs.proxyApi, optsD, body);
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        let message = error?.response?.data?.message;
        try {
          const { errors = {} } = JSON.parse(message);
          const [errorMessage] = Object.values(errors);
          message = errorMessage || null;
        } catch (e) {
          message = null;
        }
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        const snackbarPayload = message
          ? { open: true, type: ALERT_TYPE.error, message }
          : {
              open: true,
              type: ALERT_TYPE.error,
              messageKey: 'snackbar.somethingWentWrong',
            };
        yield put({ type: appTypes.OPEN_SNACK_BAR, payload: snackbarPayload });
      }
    },
  );
}

export function* updatTaskSaga() {
  yield takeLatest(types.UPDATE_ISSUE_SAGA, function* updateTask({ payload }) {
    try {
      yield put({ type: types.PROJECT_PROCESSING, payload: true });
      const { issueKeyOrId, projectId, siteId } = payload;
      const optsD = {
        headers: {
          siteid: siteId,
          relayapi: `/rest/api/3/issue/${issueKeyOrId}`,
          relaymethod: 'GET',
        },
      };
      const issueRaw = yield call(atlasAPIs.proxyApi, optsD);
      yield put(
        actions.updateTask({
          projectId,
          issueKeyOrId,
          fields: issueRaw?.data?.fields,
          siteId,
        }),
      );
      yield put({ type: types.PROJECT_PROCESSING, payload: false });
    } catch (error) {
      let message = error?.response?.data?.message;
      try {
        const { errors = {} } = JSON.parse(message);
        const [errorMessage] = Object.values(errors);
        message = errorMessage || null;
      } catch (e) {
        message = null;
      }
      const snackbarPayload = message
        ? { open: true, type: ALERT_TYPE.error, message }
        : {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          };
      yield put({ type: appTypes.OPEN_SNACK_BAR, payload: snackbarPayload });
    }
  });
}
export function* editIssueStatusSaga() {
  yield takeLatest(
    types.EDIT_ISSUE_STATUS_SAGA,
    function* addIssueDescription({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { issueId, data, projectId, siteId } = payload;
        const optsD = {
          headers: {
            siteid: siteId,
            relayapi: `/rest/api/3/issue/${issueId}/transitions`,
            relaymethod: 'POST',
          },
        };
        const body = { transition: data };
        yield put(
          actions.editIssueStatus({ data, projectId, issueId, siteId }),
        );
        yield call(atlasAPIs.proxyApi, optsD, body);
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}

export function* fetchIssueFieldsSaga() {
  yield takeLatest(
    types.FETCH_FIELD_SAGA,
    function* addIssueDescription({ payload }) {
      try {
        yield put({ type: types.PROJECT_PROCESSING, payload: true });
        const { siteId } = payload;
        const { data } = yield call(
          siteAPIs.getIssueFieldsFromAtlassian,
          siteId,
        );
        const { fields } = data;
        yield put(actions.setIssueFields({ data: fields }));
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
      } catch (error) {
        yield put({ type: types.PROJECT_PROCESSING, payload: false });
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}
